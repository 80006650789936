<template>
  <section class="boardWrap">
    <navi-bar>
      {{$t('front.common.withdrawalApply')}}
    </navi-bar>
    <template v-if="type == 'apply'">
      <div class="tab">
        <a @click="currentTab = 'usdt'" :class="{'on': currentTab == 'usdt'}">USDT (TRC20) 출금신청</a>
        <a @click="currentTab = 'ptp'" :class="{'on': currentTab == 'ptp'}">P2P로 판매하기</a>
      </div>
      <template v-if="currentTab == 'usdt'">
        <div class="wWrap pt-20">
          <div class="moneyread">
            <h3>내 지갑으로 출금신청</h3>
            <ul>
              <li><span>USDT (TRC20) 코인으로 24시간 언제든지 출금이 가능합니다.</span></li>
              <li><span>USDT (TRC20) 코인이 아니거나 출금주소를 잘못 입력 할 경우 암호화 화폐의 특성상 취소가 불가능합니다.</span></li>
            </ul>
          </div>
          <div class="outmoneywrite">
            <input type="text" class="numb w100w" pattern="[0-9.,]+" :placeholder="$t('front.give.moneyInput')" v-model="amount" />
          </div>
          <p class="outmoney">네트워크</p>
          <div class="outmoneywrite">
            <select>
              <option>TRC20</option>
            </select>
            <a class="btn" @click="calculation">계산하기</a>
          </div>
          <p class="outmoney">출금캐시</p>
          <div class="outmoneywrite">
            <img src="@/assets/img/icon_coin.svg" />
            <span>{{thousand(price)}}</span>
          </div>
          <p class="outmoney">출금 지갑 주소</p>
          <div class="outmoneywrite">
            <input type="text" class="numb w100w" v-model="to_address" />
            <a class="copy"><img src="@/assets_mobile/img/icon_copy.svg" /></a>
          </div>
          <p class="outmoney">출금비밀번호</p>
          <div class="outmoneywrite">
            <input type="text" class="numb w100w" pattern="[0-9.,]+" v-model="cashOutPass" />
          </div>
          <p class="outmoney">출금 가능 잔액</p>
          <div class="outmoneywrite">
            <span>{{thousand(userData.cashAmt)}}</span>
          </div>
          <ul class="applimoneybtn">
            <li><a @click="setMoney(5)">+5</a></li>
            <li><a @click="setMoney(10)">+10</a></li>
            <li><a @click="setMoney(20)">+20</a></li>
            <li><a @click="setMoney(50)">+50</a></li>
          </ul>
          <ul class="applimoneybtn">
            <li><a @click="setMoney(80)">+80</a></li>
            <li><a @click="setMoney(100)">+100</a></li>
            <li><a @click="setMoney(200)">+200</a></li>
            <li><a @click="setMoney(0)">{{$t('front.cash.correct')}}</a></li>
          </ul>
          <div class="moneyread">
            <ul class="coinInfo">
              <li>
                <em>출금 신청한 USDT</em>
                <span>{{thousand(amount)}} usdt</span>
              </li>
              <li>
                <em>현재 환율</em>
                <span>{{thousand(usdtToKRW)}}</span>
              </li>
              <li>
                <em>출금 수수료</em>
                <span>0</span>
              </li>
              <li>
                <em>최종 출금 금액</em>
                <span>{{thousand(price)}}</span>
              </li>
            </ul>
          </div>

          <div class="btnwrap">
            <a class="ok" @click="onSubmit">{{thousand(price)}} 캐시 {{$t('front.board.apply')}}</a>
          </div>
        </div>
      </template>
      <template v-if="currentTab == 'ptp'">
        <div class="wWrap pt-20">
          <div class="moneyread">
            <h3>P2P로 판매하기</h3>
            <ul>
              <li><span>USDT (TRC20) 코인으로 24시간 언제든지 출금이 가능합니다.</span></li>
              <li><span>USDT (TRC20) 코인이 아니거나 출금주소를 잘못 입력 할 경우 암호화 화폐의 특성상 취소가 불가능합니다.</span></li>
            </ul>
          </div>
          <div class="outmoneywrite">
            <input type="text" class="numb w100w" pattern="[0-9.,]+" :placeholder="$t('front.give.moneyInput')" v-model="amount" />
          </div>
          <p class="outmoney">네트워크</p>
          <div class="outmoneywrite">
            <select>
              <option>TRC20</option>
            </select>
            <a class="btn" @click="calculation">계산하기</a>
          </div>
          <p class="outmoney">예금주</p>
          <div class="outmoneywrite">
            <input type="text" class="numb w100w" v-model="bank_holder" />
          </div>
          <p class="outmoney">은행명</p>
          <div class="outmoneywrite">
            <input type="text" class="numb w100w" v-model="bank_name" />
          </div>
          <p class="outmoney">계좌번호</p>
          <div class="outmoneywrite">
            <input type="text" class="numb w100w" pattern="[0-9.,]+" v-model="bank_account" />
          </div>
          <p class="outmoney">출금비밀번호</p>
          <div class="outmoneywrite">
            <input type="text" class="numb w100w" pattern="[0-9.,]+" v-model="cashOutPass" />
          </div>
          <p class="outmoney">출금캐시</p>
          <div class="outmoneywrite">
            <img src="@/assets/img/icon_coin.svg" />
            <span>{{thousand(price)}}</span>
          </div>
          <p class="outmoney">출금 가능 잔액</p>
          <div class="outmoneywrite">
            <span>{{thousand(userData.cashAmt)}}</span>
          </div>
          <ul class="applimoneybtn">
            <li><a @click="setMoney(5)">+5</a></li>
            <li><a @click="setMoney(10)">+10</a></li>
            <li><a @click="setMoney(20)">+20</a></li>
            <li><a @click="setMoney(50)">+50</a></li>
          </ul>
          <ul class="applimoneybtn">
            <li><a @click="setMoney(80)">+80</a></li>
            <li><a @click="setMoney(100)">+100</a></li>
            <li><a @click="setMoney(200)">+200</a></li>
            <li><a @click="setMoney(0)">{{$t('front.cash.correct')}}</a></li>
          </ul>

          <div class="moneyread">
            <ul class="coinInfo">
              <li>
                <em>출금 신청한 USDT</em>
                <span>{{thousand(amount)}} usdt</span>
              </li>
              <li>
                <em>현재 환율</em>
                <span>{{thousand(usdtToKRW)}}</span>
              </li>
              <li>
                <em>출금 수수료</em>
                <span>0</span>
              </li>
              <li>
                <em>최종 출금 금액</em>
                <span>{{thousand(price)}}</span>
              </li>
            </ul>
          </div>

          <div class="btnwrap">
            <a class="ok" @click="onSubmitP2P">{{thousand(price)}} 캐시 {{$t('front.board.apply')}}</a>
          </div>
        </div>
      </template>
      <div class="applylistbtn pb100">
        <a :class="{'on' : type == 'list'}" @click="type = 'list'">{{$t('front.mypage.withdrawalList')}} ></a>
      </div>
    </template>
    <template v-if="type == 'list'">
      <date-filter-mobile @search="onCashListSearch"
                          @update="onChangeDate"
      />

      <div class="gamebetwrap">
        <template v-if="cashList.length > 0">
          <div class="gamebetlist" v-for="item in cashList" v-bind:key="item.cashIdx">
            <ul class="gamebetdetail textleft">
              <li class="ft13">{{computedCashStatus(item.cashStatus).text}} {{dateFormat(item.updDate)}}</li>
              <li class="ft15 mt10">{{$t('front.board.withdrawMoney')}}</li>
            </ul>
            <ul class="gamebetdetail textright">
              <li class="mt30 ft15"><span class="bl fb">{{thousand(item.cashAmt)}}</span> 원</li>
            </ul>
          </div>
        </template>
        <template v-else>
          <div class="gamebetlist">
            {{$t('front.common.notFoundList')}}
          </div>
        </template>
      </div>
      <pagination-mobile v-if="pageInfo"
                         :pageNum="pageInfo.page"
                         :pageSize="pageInfo.count_per_list"
                         :totalCount="pageInfo.tatal_list_count"
                         @goToPage="onCashListSearchMobile"
      />
      <div class="applylistbtn left mt10 mb100">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'">&lt; {{$t('front.common.withdrawalApply')}}</a>
      </div>
    </template>

  </section>
</template>

<script>

import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Exchange from '@/views/member/money/coinExchange'

export default {
  name: 'exchange',
  data () {
    return {
      currentTab: 'usdt',
      type: 'apply'
    }
  },
  components: {
    NaviBar,
    PaginationMobile,
    DateFilterMobile
  },
  mixins: [
    Exchange
  ]
}
</script>
<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped>
.pt-20 {padding: 20px 0;}
.tab {display: flex;align-items: center;gap: 10px;margin: 0 25px 15px !important;}
.tab a {width: 150px;height: 30px;text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);font-size: 12px;
    color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    border: 1px solid transparent;
    background-image: linear-gradient(to bottom, #6a6b6f, #404450), linear-gradient(to bottom, #b0772a, #ddb763 16%, #f4d780 25%, #f4d780 46%, #fbf9d8 65%, #f4d780 75%, #b0772a);
    background-origin: border-box;
    background-clip: content-box, border-box;
    display: flex;
    align-items: center;
    justify-content: center;}
.tab a.on {background: #ceb790;}
.outmoneywrite input {text-align: right;}
.coinInfo li {margin: 0;justify-content: space-between;}
.outmoneywrite .copy {padding: 0;background: none;}
.morebtn {margin-bottom: 10px !important;}
</style>
